<template>
  <router-link class="thumbnail-link flex flex-column" :to="hreftarget" >
      <Thumbnail :imgsrc="imgsrc" :alttxt="alttxt" :canFullscreen="false" :position="position"/>
      <h4 class=""> {{ alttxt }} </h4>
  </router-link>
</template>

<script>
import Thumbnail from '@/components/Thumbnail.vue'

export default {
  name: 'ThumbnailLink',
  props: {
    imgsrc: String,
    alttxt: String,
    hreftarget: String,
    position: {
        type: String,
        default: "center"
    }
  },
  components: {
      Thumbnail
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
    color:gray;
    text-decoration:none;
    width:auto;
    height:auto;
    display:inline-block;
}
h4 {
    text-align:center;

}


</style>
