<template>
  <nav id="nav">
      <h2 class="d-inline-block" ><router-link to="/">Saga Rivera</router-link></h2>
      <button id="collapse" @click="toggleCollapsed" :class="{ rotated : !collapsed}" v-show="smallScreen || mobile" class="fas fa-bars"></button>
      <div id="verticalMenu" v-show='!collapsed && (smallScreen || mobile)' :class="{ expanded : !collapsed}" >
        <router-link to="/illustration">Illustration{{ border2 }}</router-link>
        <!--router-link to="/design">Design{{ border2 }}</router-link-->
        <router-link to="/other">Other{{ border2 }}</router-link>
        <router-link to="/about">About{{ border2 }}</router-link>
      </div>
    <span v-show="!(smallScreen || mobile)">
        <router-link to="/illustration">Illustration</router-link>{{ border }}
        <!--router-link to="/design">Design</router-link-->
        <router-link to="/other">Other</router-link>{{ border }}
        <router-link to="/about">About</router-link>
    </span>
  </nav>
</template>
<script>
export default{
    name: "NavBar",


    data() {
        return {
            border:" // ",
            border2:" |",
            collapsed: true,
            minWidth: 600,
            smallScreen:false,
            mobile:false,
        }
    },
    methods:{
        toggleCollapsed() {
            this.collapsed = !this.collapsed;
            console.log(this.collapsed);
            console.log("smallScreen", this.smallScreen)
        },
        onResize(){
            this.smallScreen = (window.innerWidth <= this.minWidth)
        }
    },
    computed: {
        classIfCollapsed: function()  {
            console.log(window.innerWidth)
            console.log('hello')
            return !this.collapsed && window.innerWidth <= this.minWidth ? "visible" : "invisible"  
        },
    },
    mounted() {
        this.mobile=navigator.userAgent.toLowerCase().includes("mobile")
        window.addEventListener('resize',this.onResize);
        console.log(this.mobile)
    },
    beforeUnmount() {
        window.removeEventListener('resize',this.onResize);
    }

}

</script>
<style>
#app {
  text-align: center;
  background:#ddd!important;
  color:#777;
  font-family:"VT323", monospace
}

#nav {
  text-align:left;
  padding: 10px 10vw;
  display:block;
  margin-top:2em;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  color:inherit;
  text-decoration:none;
}

#nav a.router-link-exact-active {
  color: #42b983;
  color: pink;
  color:#556bad;
}
#nav span, #verticalMenu {
    position:absolute;
    right:10vw;
    top:3.2em;
}
h2{
    margin-right: auto;
    justify-content:flex-start;
}
h2>a{
    color:pink!important;
    color:#556bad!important;
}
h2:before{
    content:'⁺˚*･_'
}
h2:after{
    content:'_･*˚⁺'
}
#collapse, #verticalMenu {
    transition:.5s;
    transition:0s;
    cursor:pointer;
    
}
.gallery-wrapper{
    text-align: left;
    margin:auto;
    width:95vw;
}


@media screen and (max-width:800px) {
    h2 {
        padding-right:10vw;
        
    }
    #nav span {
        position:absolute;
        right:10vw;
    }
}
    button{
        background:none;
        border:none;
        color:#888;
    }
    #nav span{
        visibility:hidden;
        visibility:visible;
    }
    .invisible{
        height:0px;
        visibility:hidden;
        visibility:visible;
    }
    .visible{
        visibility:visible;
    }
    #nav{
        margin-top:1em;
        margin-bottom:2em;
        
    }
    h2 {
        padding-right:5vw;
    }
    #verticalMenu>a{
        display:block;
        animation:dropDown 300ms linear forwards;
        opacity:0;
    }
    #verticalMenu>a:nth-of-type(2){
        animation-delay:20ms;
    }
    #verticalMenu>a:nth-of-type(3){
        animation-delay:40ms;
    }
    #verticalMenu>a:nth-of-type(4){
        animation-delay:60ms;
    }
    #collapse{
        display:block;
        transition:.3s;
        transform:rotate(0deg);
    }
    .rotated{
        transition:.2s;
        transform:rotate(90deg)!important;
    }

    #verticalMenu, #collapse{
        visibility:visible;
        top:2.2em;
        position:absolute;
        right:5vw;
        font-weight:600
    }
    #verticalMenu{
        top:3.5em;
        right:6vw;
        text-align:right;
        background:#ddd;
        background:none;
        
    }
    .expanded{
    }
    @keyframes dropDown {
        0%{
            opacity:0;
            transform:rotateX(-180deg);
            transform:translateY(-1em);
        }
        75%{
            transform:rotateX(20deg)
        }
        100%{
            opacity:1;
            transform:rotateX(0deg);
            transform:translateY(0em);
        }
    }
</style>
