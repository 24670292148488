import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const title_suffix = " ++ saga"
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/illustration',
    name: 'Illustration',
    meta: {
        title: "illustration" + title_suffix
    },
    props: {
        page: "illustration",
        imageList: [
              /*{ src: 'indigo.png', alt: 'fantasy illustration'},*/
              { src: 'mermaid.png', alt: 'Mermaid song' },
              { src: 'tentacles.png', alt: 'tentacles'},
              { src: 'castlebg.png', alt: 'fantasy medieval town' },
              { src: 'tamarinne.png', alt: 'Tamarinne from Epic Seven' , position: "top"},
              { src: 'sunburn.png', alt: 'sunburn'},
              { src: 'mechanist.png', alt: 'mechanist'},
              { src: 'bedroom.png', alt: 'A bedroom render and paint for CDOM from Epic Seven' , position: "top" },
              { src: 'purplearcher.png', alt: 'young archer seeing new sights', position:"right"},
              { src: 'conceptpink.png', alt: 'game concept character art for an adventurer in a caves crawler'},
              { src: 'horseprocession.png', alt: 'horse procession', position:"left" },
              { src: 'townwave.png', alt: 'Town background', position:'right'},
              { src: 'dogwalk.png', alt: 'night dog walk'},
              { src: 'hammer.png', alt: 'Blue Warrior Ladies'},
              /*{ src: 'conceptpixel.png', alt: 'game concept environment art for an adventurer in a caves crawler game'},*/
              { src: 'whitegreencross.png', alt: 'girl in dress with cross', position:'top'},
              { src: 'priestess.png', alt: 'priestess in a forest', position:"right"},
              { src: 'redrocks.png', alt: 'red rocks'},
              { src: 'alley.png', alt: 'skaters in a back alley'},
              { src: 'cloudsrainbow.png', alt: 'double rainbow' , position:'bottom'},
              { src: 'priestess_encounter.png', alt: 'priestess encounter', position:"right"},
              { src: 'mirror.png', alt: 'mirror encounter'},
              { src: 'fairy2.png', alt: 'fairy', position:"left"},
              { src: 'brushlandscape3.png', alt: 'landscape', position: 'left'},
              { src: 'fire.png', alt: 'Subtly cybered lady walks away from burning server room'},
              /*{ src: 'statue.png', alt: 'Statue in a cave'},*/
              { src: 'greencurly.png', alt: 'Green lady'},
              { src: 'green.png', alt: 'Green man', position: 'left'},
              { src: 'orc.png', alt: 'Orc lady'},
              /*{ src: 'religion.png', alt: "religion"},
              { src: 'col.png', alt: 'water'}, */
              { src: 'pearl.png', alt: 'Ribbons and pearls'},
        ]
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/GalleryPage.vue' )
  }, 
  {
    path: '/studies', 
    name: 'Studies', 
    meta: {
        title: "studies" + title_suffix
    },
    props: {
        page: "studies", 
        imageList: [

            { src: 'liamwong.png', alt: 'Study of Liam Wong\'s Photography', },
            { src: 'mousie.png', alt: 'Study of Mouse'},
            { src: 'boxer.png', alt: 'boxer', },
            { src: 'glass.png', alt: 'Glass Still life study' },
            { src: 'texasmapcrunch.png', alt: 'Study of a mapcrunch.com in texas'},
            { src: 'portraitofalof.png', alt: 'Study of a screenshot from Portrait of a Lady on Fire (2019)'},
            { src: 'deepred.jpg', alt: 'Study of a screenshot from Deep Red (1975) '},
            { src: 'still.png', alt: 'Still life study', position:"bottom" },
            /*{ src: 'schmidt.png', alt: 'Study of a portrait by Richard Schmidt'},
            { src: 'sargentstudy.png', alt: 'Study of a portrait by Sargent'},*/
            { src: 'schmidt.png', alt: 'Study of a portrait by Richard Schmidt'},
            { src: 'ouatinthewest.png', alt: 'Study of a screenshot from Once Upon a Time in the West (1968)', position:"right"},
            { src: 'crimson.png', alt: 'Study of a screenshot from Crimson Peak (2015)'},
            { src: 'neptune.png', alt: 'Study of a hand holding a gecko named Neptune', },
            { src: 'heads.png', alt: '10 heads from #100headschallenge'},

        ]
    },
    component: () => import( '../views/GalleryPage.vue' )
  },
  {
    path: '/pleinair', 
    name: 'Plein Air', 
    meta: {
        title: "Plein Air" + title_suffix
    },
    props: {
        page: "pleinair", 
        imageList: [

            //{ src: 'texasmapcrunch.png', alt: 'Study of a mapcrunch.com in texas'},
            //{ src: 'plein/mp.png', alt: 'Study of a mapcrunch.com in texas'},
            { src: 'plein/sweet.png', alt: 'sweet cafe'},
            { src: 'plein/bread.png', alt: 'bread'},
            { src: 'plein/lake.png', alt: 'lake '},
            { src: 'plein/cafecouch.png', alt: 'cafe couch'},
            { src: 'plein/road.png', alt: 'road '},
            { src: 'plein/chairs.png', alt: 'chairs '},
            { src: 'plein/cake_before.png', alt: 'cake before'},
            { src: 'plein/cake_after.png', alt: 'cake after'},
            { src: 'plein/windowsnow.png', alt: 'window snow'},
            { src: 'plein/windowcat.png', alt: 'cat window'},
            { src: 'plein/windowstreet.png', alt: 'window street'},
            { src: 'plein/treewindow2.png', alt: 'trees window'},
            { src: 'plein/treewindow3.png', alt: 'trees window 2'},
            { src: 'plein/foodcourt.png', alt: 'food court'},
            { src: 'plein/whalelot.png', alt: 'whale cloud plein air'},
            { src: 'plein/fountain.png', alt: 'fountain'},
            { src: 'plein/first.png', alt: 'first plein air'},


        ]
    },
    component: () => import( '../views/GalleryPage.vue' )
  }, 
  {
    path: '/characters', 
    name: 'Characters', 
    meta: {
        title: "Characters" + title_suffix
    },
    props: {
        page: "Characters", 
        imageList: [

            { src: 'mechanist.png', alt: 'mechanist'},
            { src: 'conceptpink.png', alt: 'character concept art'},
            { src: 'whitegreencross.png', alt: 'girl in dress with cross', position:'top'},
            { src: 'sunburn.png', alt: 'girl with sunburn'},
            { src: 'redrocks.png', alt: 'red rocks'},
            { src: 'orc.png', alt: 'orclady'},
            { src: 'priestess.png', alt: 'priestess in a forest', position:"right"},
            { src: 'scrap/y2k.png', alt: 'y2k original character sketch'},
            { src: 'wbanner.png', alt: 'character banner', position:'right'},

        ]
    },
    component: () => import( '../views/GalleryPage.vue' )
  },
  {
    path: '/scrap', 
    name: 'sketches & wip', 
    meta: {
        title: "Sketch/WIP" + title_suffix
    },
    props: {
        page: "Sketch Wip", 
        imageList: [

            { src: 'scrap/priestwip.png', alt: 'priest original character art wip'},
            { src: 'scrap/indigowip3.png', alt: 'indigo wip'},
            { src: 'scrap/demonkitties.png', alt: 'demon kitties wip'},
            { src: 'scrap/mapsketch.png', alt: 'project character and style concept sketch'},
            { src: 'girlcat.png', alt: 'girl with cat sketch', position:'top'},
            { src: 'scrap/wbat.png', alt: 'bat original character sketch', position:'top'},
            { src: 'scrap/jar.png', alt: 'phone finger sketch'},
            { src: 'scrap/figure_wip.png', alt: 'figure drawing sketch wip'},
            { src: 'scrap/treesvalues.png', alt: 'trees values sketch'},
            { src: 'scrap/greenorange.png', alt: 'greenorange'},
            { src: 'scrap/curves_sketches.png', alt: 'curves_sketches'},
            { src: 'scrap/hand.png', alt: 'hand'},
            //{ src: 'scrap/page.png', alt: 'page'},
            //{ src: 'scrap/servals.png', alt: 'servals', position:'left'},
            { src: 'scrap/yoga.png', alt: 'yoga 90mac figure exercise'},
            { src: 'scrap/conceptpixel.png', alt: 'concept cave pixel'},


        ]
    },
    component: () => import( '../views/GalleryPage.vue' )
  },
  {
    path: '/other', 
    name: 'Other', 
    meta: {
        title: "other" + title_suffix
    },
    component: () => import( '../views/Other.vue' )
  },
  {
    path: '/design', 
    name: 'Design', 
    meta: {
        title: "design" + title_suffix
    },
    props: {
        page: "design",
        imageList: [
            { src: 'design/3y3_00.png', alt: '3y3 webpage experimentations'},
            { src: 'design/stickers.png', alt: 'cat stickers created for messaging applications', position: "left"},
            { src: 'design/tiger.png', alt: '2022 Year of the Tiger'},
            { src: 'design/vol3.png', alt: 'when the time comes vol.3 '},
            { src: 'design/joycrashing.png', alt: 'Illustration and typography'},
            { src: 'design/ambienteye.png', alt: 'Ambient Eye concert poster'},
            { src: 'design/desertion.png', alt: 'Desertion Trio concert poster'},
            //{ src: 'design/webfuzzer.jpg', alt: 'webfuzzing application built with flask + scrapy + postgres'},
            //{ src: 'design/foodies.png', alt: ' mock food social media service built with React'},
            { src: 'design/website.png', alt: 'my art portfolio site built with vue'},
            { src: 'design/tsss.png', alt: 'personal project time management app mockups'},
            //{ src: 'design/flowercard.png', alt: 'Well wishes card'},
            { src: 'design/doomsday.png', alt: 'album cover mockup'},
            { src: 'design/logicalfallacies.png', alt: 'logical fallacies graphic'},
            { src: 'design/wave.png', alt: 'pixel sorting experimentation iteration with Audacity and Processing', position: 'bottom'},
        ]
    },
    component: () => import( '../views/GalleryPage.vue' )
  },
  {
    path: '/portraits', 
    name: 'Portraits', 
    meta: {
        title: "portraits" + title_suffix
    },
    props: {
        page: "portraits",
        imageList: [
              { src: 'greencurly.png', alt: 'Green lady'},
              { src: 'mirror.png', alt: 'mirror encounter'},
              { src: 'mechanist.png', alt: 'archivist mechanist'},
              { src: 'green.png', alt: 'Green man, Sile', position: 'left'},
              { src: 'orc.png', alt: 'Orc lady'},
              { src: 'vampire.png', alt: 'vampire'},
              { src: 'cee.png', alt: 'Tattoo and pink slip'},
              { src: 'mothgirl.png', alt: 'Moth girl'},
              { src: 'parsnip.png', alt: 'Portrait of Hannah'},
              { src: 'white.png', alt: 'White lady', position: 'bottom'},
        ]
    },
    component: () => import( '../views/GalleryPage.vue' )
  }, 
  {
    path: '/animals', 
    name: 'animals', 
    meta: {
        title: "animals" + title_suffix
    },
    props: {
        page: "animals",
        imageList: [
            { src: 'mousie.png', alt: 'Study of Mouse'},
            { src: 'design/tiger.png', alt: '2022 Year of the Tiger'},
            { src: 'comms/notideart.png', alt: 'Fanart/DTIYS of notideart'},
            { src: 'candlekitty.png', alt: 'kitty and a candle'},
            { src: 'design/stickers.png', alt: 'cat stickers created for messaging applications', position: "left"},
        ]
    },
    component: () => import( '../views/GalleryPage.vue' )
  },
  {
    path: '/commissions', 
    name: 'Commissions & Fanart', 
    meta: {
        title: "commissions & fanart" + title_suffix
    },
    props: {
        page: "commissions & fanart",
        imageList: [
            { src: 'comms/campfire.gif', alt: 'Animated campfire from Epic Seven' },
            { src: 'tamarinne.png', alt: 'Tamarinne from Epic Seven' , position: "top" },
            { src: 'bedroom.png', alt: 'A bedroom render and paint for CDOM from Epic Seven' , position: "top" },
            { src: 'comms/xilatr_saryna.png', alt: 'Portrait of Saryna (belongs to xilatr)' },
            { src: 'comms/PEIGI1045-kiki.png', alt: 'Portrait of Kiki (belongs to PEIGI1045)' },
            { src: 'comms/JesterFesta_Tye.png', alt: 'Portrait of Tye (belongs to JesterFesta)', position: "top" },
            { src: 'comms/alierynart-clara.png', alt: 'Portrait of Clara (belongs to alierynart)'},
            { src: 'comms/smileyfacejpg_fatherjackson.png', alt: 'Portrait of Father Jackson (belongs to smileyfacejpg)', position: "top" },
            { src: 'comms/skycliffe_solace.png', alt: 'Portrait of Solace (belongs to skycliffe)'},
            { src: 'comms/MWYNHAU_chiba-mushrooms.png', alt: 'Portrait of mushrooms (belongs to MWYNHAU and chiba)' },
            { src: 'comms/vincethebinch_akira.png', alt: 'Portrait of Akira (belongs to vincethebinch)', position: "top"},
            { src: 'comms/mangomango.png', alt: 'Portrait of unnamed (belongs to mangomango)' },
            { src: 'comms/levisofa_kazuhiro.png', alt: 'Portrait of Kazuhiro (belongs to levisofa)' },
            { src: 'comms/KoLBA_don.png', alt: 'Portrait of don (belongs to KoLBA)', position: 'top' },
            { src: 'comms/maelstrom.png', alt: 'Fanart of the Maelstrom design from Cyberpunk 2077' , position:'top'},
            { src: 'comms/wyrmsfornerves_catbat.png', alt: 'Portrait of catbat (belongs to wyrmsfornerves)' },
            { src: 'comms/aceofale_korinnasucco.png', alt: 'Portrait of Korinna Succo (belongs to aceofale)' , position:"top"},
            { src: 'comms/samchovies_camille.png', alt: 'Portrait of Camille (belongs to samchovies)' },
            { src: 'comms/notideart.png', alt: 'Fanart/DTIYS of notideart'},
            { src: 'comms/Saturnwonders-hanaJeong.png', alt: 'Portrait of Hana (belongs to Saturnwonders)' },
            { src: 'candlekitty.png', alt: 'kitty and a candle'},
        ]
    },
    component: () => import( '../views/GalleryPage.vue' )
  },
  {
    path: '/about', 
    name: 'About', 
    meta: {
        title: "about" + title_suffix
    },
    component: () => import( '../views/About.vue' )
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to, from, next) => {
    /* It will change the title when the router is change*/
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next();
});

export default router
