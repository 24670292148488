<template>
  <navbar />
  <router-view/>
</template>
<style>
html{
    background:#ddd;
    transition:.5s;
    font-size:1.5m;
}
#font-face{
    font-family: "VT323";
    src: local("VT323"), url(./assets/VT323-Regular.ttf) format("truetype");
}
h1,h2,h3{
    color:#556bad;
}
h1:before,h2:before,h1:after,h2:after{
    color:#777!important;
}
h1,h2{
    text-transform:lowercase;
    color:#C44AAF;
    color:#777
}
h1:before{
    content:'*°:⋆━━━ ';
    content:'./'
}
h1:after{
    content:' ━━━⋆:°*';
    content:''
}
</style>
<script>
import navbar from "@/components/NavBar.vue"

export default{
    name: "App",
    components: {
        navbar
    }
}


</script>
