<template>
  <div class="home">
  <h1>Portfolio</h1>
    <div class="gallery-wrapper flex flex-row h-50">
        <ThumbnailLink v-for="link in list"  v-bind:key="link.target" :imgsrc="link.src" :alttxt="link.alt" :hreftarget="link.target" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ThumbnailLink from '@/components/ThumbnailLink.vue'

export default {
  name: 'Home',
  data() {
      return {
          list: [
              { src: 'tentacles.png', alt: 'Illustration', target: '/illustration'},
              { src: 'design/3y3_00.png', alt: 'Design', target: '/design'},
              { src: 'mousie.png', alt: 'Other', target: '/other'},
          ]
      }

  },
  components: {
    ThumbnailLink

  }
}
</script>
