<template>
  <div class="thumbnail" >
      <img v-if="canFullscreen" :src="require(`@/assets/${imgsrc}`)" :alt="alttxt" @click="openCarousel" :class="'thumbpos-'+position"/>
      <img v-else :src="require(`@/assets/${imgsrc}`)" :alt="alttxt"/>
  </div>
</template>


<script>
export default {
  name: 'Thumbnail',
  props: {
    imgsrc: String,
    alttxt: String,
    canFullscreen: {
        type: Boolean,
        default: true
    },
    position: {
        type: String,
        default: "center"
    }
  },
  data() {
      return{
        carousel: false,
      }
  },
  emits: ['fullImage'],
  methods: {
      openCarousel() {
          //this.carousel = true;
          console.log('clik');
          this.$emit('fullImage', this.imgsrc, this.alttxt);

      },
  },
    
  computed: {
        
    },
    updated() {
        /*
        if (this.carousel) {
            this.$refs.veil.focus()
        }*/
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.thumbnail{
    display:inline-block;
    overflow:hidden;
    margin:3.25vw;
    margin-bottom:1%;
    margin-top:5vw;
    
    

}
img{
    width:25vw;
    height:25vw;
    min-width:100px;
    min-height:100px;
    object-fit:cover;
}
.thumbpos-left{
    object-position:0;
}
.thumbpos-right{
    object-position:100%;
}
.thumbpos-bottom{
    object-position:50% 100%;
}
.thumbpos-top{
    object-position:50% 0%;
}
.veil{
    background:#000000e0;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    text-align:center;
    overflow:hidden;
}
.fullscreen{
    width:auto;
    margin:0px auto;
    height:95vh;
    width:100vw;
    padding:1em;
    moz-object-fit:contain;
    object-fit:contain;

}
h4{
    margin-bottom:0px;
}
#exit-carousel, #next-carousel, #prev-carousel{
    position: fixed;
    color:grey;
    font-size:2em;
    cursor:pointer;
    padding:.5rem;
    
}
#exit-carousel{
    top:0em;
    right:1.5rem;
    font-size:1.5em;
    padding:0;
    font-weight:600;
}
#next-carousel{
    top:50vh;
    right:1.5rem;
}
#prev-carousel{
    top:50vh;
    left:1.5rem;
}




</style>
